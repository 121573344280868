import { title } from 'assets/jss/material-kit-react.js'

const searchStyle = {
  section: {
    padding: '15px 0',
    textAlign: 'center',
  },
  title: {
    ...title,
    marginBottom: '1rem',
    marginTop: '15px',
    minHeight: '15px',
    textDecoration: 'none',
  },
  description: {
    color: '#999',
  },
}

export default searchStyle
