/* eslint-disable react/prop-types */
import React from 'react'

import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-kit-react/views/sections/searchStyle.js'

const useStyles = makeStyles(styles)

const SearchSection = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <aside className="mdc-drawer shrine-drawer"></aside>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default SearchSection
